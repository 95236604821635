import React from "react";
import { Helmet } from "react-helmet";
import SeoText from "../../data/messages/seo.en";

const Seo = ({titleId, descriptionId}) => {
    return (
        <Helmet>
            <title>{SeoText[titleId]}</title>
            {!!descriptionId && <meta name="description" content={SeoText[descriptionId]} />}
        </Helmet>    
    );
};

export default Seo;