export default {
    homepageTitle: "Tyviso | Brand Partnerships",
    homepageDescription: "Grow with Brand Partnerships. Acquire new customers, turbocharge customer lifetime value and monetise your site. No setup or monthly fees.",
    aboutTitle: "Tyviso - About us",
    aboutDescription: "Our mission: To build the world's best product recommendation engine",
    learnMoreTitle: "Tyviso - Brand to Brand Recommendations",
    learnMoreDescription: "Communities are made to help each other: Tyviso's diverse community of brands is doing just that",
    contactTitle: "Tyviso - Contact us",
    contactDescription: "Our community delivers unrivalled conversion rates and you only pay for results - apply to join",
    offersPageTitle: "Tyviso - Choose Your Favourite Offer",
    faqTitle: "Tyviso - Frequently Asked Questions",
    faqDescription: "Answers to some frequently asked questions regarding Tyviso",
    integrationGuideTitle: "Tyviso - How to get started",
    integrationGuideDescription: "A quick guide on how to get started with Tyviso",
    rewardsGuideTitle: "Tyviso Rewards - How to get started",
    rewardsGuideDescription: "A quick guide on how to get started with Tyviso Rewards",
    adSpecsTitle: "Tyviso - Ad Specs",
    adSpecsDescription: "Specifications regarding the assets needed for promoting your brand on Tyviso",
    adPreviewTitle: "Tyviso - Preview your ad",
    adPreviewDescription: "Here you can check how your ad looks like, real-time",
    pressLinksTitle: "Tyviso - Press Links",
    pressLinksDescription: "View the latest coverage of Tyviso as we lead the brand-to-brand revolution."
}